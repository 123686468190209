import React, { useState } from "react";
import Logo from "../assets/logo.png";

const LoginCard = ({ setPhoneNumber, otpRequest, verifyOtp, setOtp,spinnerSignin,signinErrorMsg,setSigninErrorMsg,otp,phoneNumber }) => {
  const [disableOtpBtn, setDisableOtpBtn] = useState(true);
  const [hideOtpInput, setHideOtpInput] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [spinner, setSpinner] = useState(false);
  const collectNumber = (e) => {
    if (e.target.value.length === 10) {
      setDisableOtpBtn(false);
      setPhoneNumber(e.target.value);
    } else {
      setDisableOtpBtn(true);
    }
  };

  const collectOtp = (e) => {
    setOtp(e.target.value);
  };

   document.onkeydown= function (event) {
    if(event.keyCode == 13 && hideOtpInput ===true && disableOtpBtn===false) {
      getOtpRequest();
    }
    else if(event.keyCode == 13 && phoneNumber.length > 9 && otp.length>3 && hideOtpInput ===false ) {
      verifyOtp();
    }
    
  }

  const getOtpRequest = async () => {
    const response = await otpRequest();
    if (response === true) {
      setSpinner(true);
      setTimeout(()=>{setSpinner(false);setHideOtpInput(false)},3000)
      setErrorMessage("");
    } else {
      setSpinner(false);
      if (response === false) {
        setErrorMessage("Please try again");
      } else {
        setErrorMessage(response);
      }
    }
  };

  return (
    <div>
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth px-0">
            <div className="row w-100 mx-0">
              <div className="col-md-9 col-lg-5 col-xl-4 mx-auto">
                <div className="text-left py-5 px-4 px-sm-5">
                  <div className="brand-logo">
                    <img src={Logo} alt="logo" />
                  </div>
                  <p className="text-center mt-5 mb-5 elect-text">
                  Association Election 2023
                  </p>

                  <form>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text1">
                            <span>+91</span>
                          </span>
                        </div>
                        <input
                          className="form-control form-control-lg input-bg LoginInput"
                          type="number"
                          placeholder="Phone number"
                          onChange={collectNumber}
                        />
                        <button
                          className={"btn btn-primary font-weight-medium min-btn-width "+ (hideOtpInput?"":"display-none")}
                          disabled={disableOtpBtn}
                          type="button"
                          onClick={getOtpRequest}
                        >
                          {spinner && (
                            <div
                            className="spinner-border text-light"
                              role="status"
                            ></div>
                          )}
                          {spinner ?"":"GET OTP"}
                        </button>
                      </div>
                      {errorMessage && (
                        <span className="text-danger h6 pt-2">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                    {!hideOtpInput && (
                      <>
                        <div className={"form-group "+ (signinErrorMsg!==""?"margin5":"")}>
                          <input
                            type="text"
                            className="form-control form-control-lg input-bg LoginInput"
                            placeholder="Enter OTP"
                            onChange={collectOtp}
                          />
                        </div>
                        {signinErrorMsg!=="" && (
                        <span className="text-danger h6 pt-2">
                          {signinErrorMsg}
                        </span>
                      )}
                        <div className="my-2 d-flex justify-content-center">
                          <span className="textDec">Didn't receive the code?</span>
                          <a onClick={()=>getOtpRequest()} className="auth-link text otpText ml-1 linkbtn">
                            Resend OTP
                          </a>
                        </div>
                        <div className="mt-3">
                          <button
                            className="btn btn-block btn-primary btn-lg font-weight-medium  min-btn-height"
                            type="button"
                            onClick={verifyOtp}
                            disabled={!spinner && otp.length>3?false:true}
                          >
                           {"SIGN IN"}
                            {/* <fa name="spinner" animation="spin"></fa> */}
                            <div name="spinner" animation="spin"></div>
                          </button>
                        </div>
                      </>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginCard;
