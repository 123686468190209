import React from 'react'
import '../components/Topbar.css'
import { Navbar, Container, Row, Col, Image } from 'react-bootstrap';
import Logo from '../assets/logo.png'
import Icons from '../components/Icons'

const Topbar = () => {
    return (
        <div>
            <Navbar bg="light">
            <Container>
                <Row className="d-flex justify-content-between align-items-center">
                    <Col xs={12} md={8}><div className=""><Navbar.Brand href="#home">{<Image  fluid  src={Logo} alt="FBOA" />}</Navbar.Brand></div></Col>
                    <Col className="d-none d-sm-none d-md-block" xs={12} md={4}><div className="d-flex justify-content-end align-items-center mt-3  ">{Icons.phone}<h3>0484-2624646</h3></div></Col>
                </Row>
            </Container>
          </Navbar>
        </div>
    )
}

export default Topbar
