import React, { useEffect } from "react";
import { Col, Card, Tab, Row,Button } from "react-bootstrap";
import CardCandidate from "./CardCandidate";

let voteLst=[];
const PostionBox = ({catidatesData,screen,OnVoteClick,voted,positionName,maxVote,voteList,onSave,positionId,isVotingEnables}) => {
const candidates=catidatesData.filter(x=>x.positionId===positionId).sort((a, b) => a.slno > b.slno ? 1 : -1);
useEffect(()=>{
console.log("__maxVote____", maxVote)
},[maxVote])
console.log("__candidates____", candidates)
if(voteList && voteList.length>0){
  voteLst=voteList.filter(x=>x.positionId===positionId);
  }
  else{
    voteLst=[];
  }
  return (
    <>
      <Col sm={9}>
        <Tab.Content className="voting-sect">
          <Card>
            <Card.Body>
              <Tab.Pane eventKey={positionId}>
                <Row>
                  <Col lg={12}>
                    <h5>
                      <b>
                        <span>{screen && screen==="voting" && !voted && isVotingEnables?"Select the ":"Contestants for "}</span>
                        <span className="text-danger">{positionName}</span>
                      </b>
                    </h5>
                  </Col>
                </Row>
                {screen && screen==="voting" && !voted && isVotingEnables &&
                <Row>
                  <Col lg={12}>
                    {/* <h6>
                      Please select <b>{"MAXIMUM  '"+maxVote+"'"}</b> from the list
                    </h6> */}
                    <div className="divInline">
                      <div className="maxVoteDiv">
                        <div>Select Maximum</div>
                        <div className="size25">{maxVote}</div>
                      </div>
                      <div className="maxVoteDiv divGray">
                      <div>Total Candidates</div>
                        <div className="size25">{candidates?candidates.length:0}</div>
                      </div>
                    </div>
                  </Col>
                </Row>
                }
                <Row>
                  {candidates && candidates.map((candidatedata, index) => {
                  return (
                  <CardCandidate key={index} candidate={candidatedata} screen={screen} position={positionName} positionId={positionId} OnVoteClick={OnVoteClick}  voted={voted} votecount={maxVote} candidateId={voteLst && voteLst[0] && voteLst[0].candidateId?voteLst[0].candidateId:[]}/>
                  )})
                  }
                </Row>
              </Tab.Pane>
            </Card.Body>
          </Card>
        </Tab.Content>
        {screen && screen==="voting" && !voted && isVotingEnables &&
                  <Row>
                      <Col xs sm="12" md="12" lg="12">
                          <div className="save-blue-btn text-right">
                              <Button variant="primary" size="lg" className="mt-4" onClick={()=>onSave()}>SAVE AS DRAFT</Button>
                          </div>
                      </Col>
                  </Row>
                }
      </Col>
    </>
  );
};

export default PostionBox;
